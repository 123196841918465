const COLLAPSED_CLASS = 'comment__list--collapsed';
const MORE_BUTTON_CLASS = 'comment__list-button';

export const button = (el) => {
  const readMoreButton = document.createElement('span');
  readMoreButton.classList.add('button', 'button--primary', MORE_BUTTON_CLASS);
  readMoreButton.innerHTML = el.getAttribute('data-more');
  readMoreButton.addEventListener('click', (e) => {
    el.classList.remove(COLLAPSED_CLASS);
    readMoreButton.remove();
  });
  el.parentNode.appendChild(readMoreButton);
};

export const enhancer = (el) => {
  el.classList.add(COLLAPSED_CLASS);
  button(el);
};
