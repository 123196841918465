// Import libraries and polyfills
import 'classlist-polyfill';
import domready from 'domready';
import rafPolyfill from './polyfills/request-animation-frame';

// Handler and Enhancer utility
import handle from './modules/handle';
import enhance from './modules/enhance';

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';

// Import handlers
import { handler as classToggler } from './modules/class-toggler';
import { handler as gtmEventHandler } from './modules/gtm-event';

// Import enhancers
import { enhancer as accordionPanel } from "./modules/accordion-panel";
import { enhancer as gtmEventEnhancer } from './modules/gtm-event';
import { enhancer as newsletterSignup } from './modules/newsletter-signup';
import { enhancer as tips } from './modules/tips';
import { enhancer as productOrder } from './modules/product-order';
import { enhancer as videoPlay } from './modules/video-play';
import { enhancer as specsBlock } from './modules/specs-block';
import { enhancer as commentList } from './modules/comment-list';
import { enhancer as commentForm } from './modules/comment-form';
// import { enhancer as shoppingCart } from './modules/shopping-cart';

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change

  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;

  if (typeof window.Promise === 'undefined') {
    window.Promise = Promise;
  }
};

const main = () => {
  executeOnReady();
  handle({
    classToggler,
    gtmEventHandler,
  });
  enhance({
    accordionPanel,
    gtmEventEnhancer,
    newsletterSignup,
    tips,
    productOrder,
    videoPlay,
    specsBlock,
    commentList,
    commentForm,
  });
};

domready(main);
