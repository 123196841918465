export const enhancer = (el) => {
  const HIDDEN_CLASS = 'comments__form-wrapper--hidden';
  const form = el.querySelector('.comments__form-wrapper');
  const button = el.querySelector('.button');
  form.classList.add(HIDDEN_CLASS);
  button.addEventListener('click', (e) => {
    form.classList.remove(HIDDEN_CLASS);
    button.remove();
  });
};
