/* eslint-disable prefer-template */
export const getVideoIframe = videoId => {
  const iframe = document.createElement("iframe");
  iframe.frameBorder = 0;
  iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
  iframe.setAttribute('src', `https://youtube.com/embed/${videoId}`);
  return iframe;
};

export const enhancer = (el) => {
  const videoId = el.getAttribute('data-video');
  const play = el.parentNode.getElementsByClassName('svg-play')[0];
  const loading = el.parentNode.getElementsByClassName('svg-loading')[0];
  const youtube = getVideoIframe(videoId);
  loading.style.display = 'none';
  el.parentNode.append(youtube, el);
  el.parentNode.addEventListener('click', (event) => {
    play.style.display = 'none';
    loading.style.display = 'block';
    youtube.setAttribute('autoplay', true);
    youtube.setAttribute('src', `https://youtube.com/embed/${videoId}?autoplay=1`);
    youtube.classList.add('video--active');
  });
};
