/* eslint-disable */
import { forEach } from './util';

export const enhanceItem = (el) => {
  const maxHeight = Math.round(document.documentElement.clientWidth / 2);
  console.log(maxHeight);
  const items = el.querySelectorAll('.tips-block__list-item');
  forEach(items, (item) => {
    const itemBody = item.getElementsByClassName('tips-block__list-item-body')[0];
    if (!item.getAttribute('data-height')) {
      item.setAttribute('data-height', itemBody.offsetHeight);
    }
    let buttonExists = item.querySelector('.tips-block__list-item-read-more-button');
    if ( buttonExists !== null ) {
      buttonExists.parentNode.classList.remove('tips-block__list-item-read-more--hidden');
    } else if (item.getAttribute('data-height') > maxHeight) {
      let readMore = item.querySelector('.tips-block__list-item-read-more');
      const readMoreButton = document.createElement('span');
      readMoreButton.classList.add('button', 'button--primary', 'tips-block__list-item-read-more-button');
      readMoreButton.innerHTML = el.getAttribute('data-more');
      readMoreButton.addEventListener('click', (e) => {
        itemBody.classList.remove('tips-block__list-item--overflow');
        readMore.classList.add('tips-block__list-item-read-more--hidden');
      });
      readMore.appendChild(readMoreButton);
    }
    if (item.classList.contains('tips-block__list-item--active')) {
    } else {
      item.classList.add('tips-block__list-item--hidden');
    }
    itemBody.classList.add('tips-block__list-item--overflow');
  });
  el.classList.remove('tips-block--overflow');
};

export const scrollIntoView = (el) => {
  const yOffset = -150;
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: y, behavior: 'smooth'});
}

export const showNext = () => {
  const activeNextItem = document.querySelector('.tips-block__list-item--active');
  if (activeNextItem.nextElementSibling === null) {
    const firstItem = document.querySelector('.tips-block__list-item');
    activeNextItem.classList.replace('tips-block__list-item--active', 'tips-block__list-item--hidden');
    firstItem.classList.replace('tips-block__list-item--hidden', 'tips-block__list-item--active');
    scrollIntoView(firstItem);
  } else {
    activeNextItem.classList.replace('tips-block__list-item--active', 'tips-block__list-item--hidden');
    activeNextItem.nextElementSibling.classList.replace('tips-block__list-item--hidden', 'tips-block__list-item--active');
    scrollIntoView(activeNextItem.nextElementSibling);
  }
};

export const showPrev = () => {
  const activePrevItem = document.querySelector('.tips-block__list-item--active');
  if (activePrevItem.previousElementSibling === null) {
    const prevItems = document.querySelectorAll('.tips-block__list-item');
    const lastPrevItem = prevItems[prevItems.length- 1];
    activePrevItem.classList.replace('tips-block__list-item--active', 'tips-block__list-item--hidden');
    lastPrevItem.classList.replace('tips-block__list-item--hidden', 'tips-block__list-item--active');
    scrollIntoView(lastPrevItem);
  } else {
    activePrevItem.classList.replace('tips-block__list-item--active', 'tips-block__list-item--hidden');
    activePrevItem.previousElementSibling.classList.replace('tips-block__list-item--hidden', 'tips-block__list-item--active');
    scrollIntoView(activePrevItem.previousElementSibling);
  }
};

export const prevButton = (el) => {
  const prevButton = document.createElement('span');
  prevButton.classList.add('button', 'button--primary', 'tips-block__list-item-button');
  prevButton.innerHTML = `<svg width="28" height="18" viewBox="0 0 28 18" xmlns="http://www.w3.org/2000/svg"><g stroke="#000" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M27 9H3" stroke-linecap="square"/><path d="M10 1L2 9l8 7.415"/></g></svg>`;
  prevButton.addEventListener('click', (e) => {
    showPrev();
    enhanceItem(el);
  });
  return prevButton;
};


export const nextButton = (el) => {
  const nextButton = document.createElement('span');
  nextButton.classList.add('button', 'button--primary', 'tips-block__list-item-button');
  nextButton.innerHTML = `<svg width="28" height="18" viewBox="0 0 28 18" xmlns="http://www.w3.org/2000/svg"><title>Slice 1</title><g stroke="#000" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M1 9h24" stroke-linecap="square"/><path d="M18 1l8 8-8 7.415"/></g></svg>`;
  nextButton.addEventListener('click', (e) => {
    showNext();
    enhanceItem(el);
  });
  return nextButton;
};

export const controllers = (el) => {
  const items = el.querySelectorAll('.tips-block__list-item-buttons');
  forEach(items, (item) => {
    item.appendChild(nextButton(el));
    item.appendChild(prevButton(el));
  });
};

export const enhancer = (el) => {
  // if (window.location.hash == '#product-order' && window.outerWidth < 425) {
  //   el.remove();
  //   return;
  // }
  el.classList.add('tips-block--overflow');
  controllers(el);
  setTimeout(function(){
    enhanceItem(el);
  }, 3000);
};
